import React, { useState, useEffect } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
    Box,
    Flex,
    Link
} from '@chakra-ui/react';

export default function PageSubMenu({ menuItems, location }) {
    const [sticky, setSticky] = useState("false");


    const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 250 ? "is-sticky" : "";
        setSticky(stickyClass);
      };
    
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () =>
            window.removeEventListener("scroll", isSticky);
    }, []
    ); 

    

    return (
        <Flex w="100%" bg={location.pathname.includes('snowscapes') ? 'snowscapes.500' : location.pathname.includes('plein-air-festival') ? 'plein-air.500'  : location.pathname.includes('scarecrow') ? 'scarecrow.300' : '#007456'} p="4" color="white" justifyContent="center" flexWrap="wrap"
        className={`header`} boxShadow="md">
            {menuItems.map((item, index) => (
                <Box key={index} px="4" textAlign="center">
                    <Link as={GatsbyLink} to={item.menu_item_link.url} color="white" _hover={{textDecoration: 'none'}} className="submenu-item" activeClassName="active" textTransform="uppercase" fontSize="clamp(0.8rem, 2vw, 1rem)">
                        {item.menu_item_label}
                    </Link>
                </Box>
            ))
            }
        </Flex>
    )
}